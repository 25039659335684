
import React,{useState} from 'react';
import {  Route, Redirect } from "react-router-dom";

import Header from '../components/header'
import Footer from '../components/footer'
import Redes from "../components/redes"
import Home from "../components/home"
import Empresa from "../components/empresa"
import Servicios from "../components/servicios";
import Mercados from "../components/mercados";
import Sale from "../components/redirect";
import MercadoCapitales from "../components/mercado_capitales";
import Equipo from "../components/equipo";       
import ModContacto from './mod_contacto'   
/*
<PrivateRoute path="/basesinternas" exact component={Bases} />
*/

//"https://api.whatsapp.com/send?phone=5491153169955&text=Hola MGMSA, quería consultarles por";

const redirect = () => {
  window.location.origin.includes("www.mgmsa.com.ar:7813") && window.location.replace('https://www.allariaagro.com.ar:7510');
}

const App = (props) => {
  const [popupState, cambiaPopupState] = useState(false)

  redirect()


  let wa = ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)) ? "https://api.whatsapp.com/send?phone=5491153169955&text=Hola AAF, quería consultarles por"  : "https://web.whatsapp.com/send?phone=5491153169955"
   
    return (
      <div>
        { popupState &&
        <div className="overlay" onClick={()=>cambiaPopupState(false)}>
          <div className="popup">
            <div style={{textAlign: "right"}}>
            <a href="#"   onClick={()=>cambiaPopupState(false)} style={{color:"#fff"}}><i className="fas fa-2x fa-times"></i></a>
            </div>
            <h4>Estimados clientes,</h4><br/>
            <p>les comunicamos que como consecuencia de la migración de plataforma que realizó el MatbaRofex, a partir del 31/08/2020 se modificó la forma en que se exhibe la información de su posición de futuros y opciones.</p>   
            <p>Por consultas al respecto por favor no dude en comunicarse con su oficial comercial.</p>
            <p>Por favor sepa disculpar las molestias que esto pudiera ocasionar.</p>
          </div>
        </div>
        }
        {/* <Redes /> */}
        
        <Header />
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/empresa/equipo" component={Equipo} />
          <Route path="/empresa/historia" component={Empresa} />
          <Route path="/servicios/agronegocios" component={Servicios} />
          {/* <Route path="/servicios/mercado_capitales" component={MercadoCapitales} /> */}
          <Route path="/servicios/mercado_capitales" component={MercadoCapitales} />
          <Route path="/contacto" component={ModContacto} />
          <Route exact path="/mercados" component={Mercados} />
          <Route exact path="/interna" component={Sale} />
        </div>
        <Footer />
        <div className="rounded-circle wa_bot">
          <a href={wa+"&text=Hola AAF, quería consultarles por"} target="_blank" style={{color:"#fff"}}>
            <i className="fab fa-2x fa-whatsapp"></i>
          </a>
        </div>
        
      </div>
    );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('isAuthenticate') ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
);

export default App;
