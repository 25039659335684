import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ModCabeceraImg from "./mod_cabecera_img";
import ModContacto from "./mod_contacto";
import ModMapa from "./mod_mapa";
import ModAgentes from "./mod_agentes";
import ModFatca from './modFATCA';
import ModOcde from './mod_OCDE';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const MercadoCapitales = props => {

    const aperturaRef = useRef(null);

    useEffect(() => {
        if (!props.match.params.anchor) {
            window.scrollTo(0, 0)
        } else if (props.match.params.anchor === "apertura") {
            window.scrollTo(0, aperturaRef.current.offsetTop - 195);
           // scrollToRef(aperturaRef.current.offsetTop-65);
        }
    });

    const goFinanciero = (e) => {
        var dest_financiero = (window.location.origin.includes("allariaagro.com.ar")) ? 'http://financiero.allariaagro.com.ar/' : 'http://groot/'
        window.location = dest_financiero;
      }

    return (
        <div>
            <div
              className="stellar header_capitales d-flex flex-column justify-content-end"
              style={{ backgroundSize:"cover" }}
              data-aos="fade"
              data-stellar-background-ratio="0.5"
            >
              <p className="h0 uppercase white">MERCADO <br/>DE CAPITALES</p><br/>
              <button type="submit" className="btn button-primary" onClick={()=>goFinanciero()}>Operá Online a través de nuestra plataforma</button>
            </div>
            
            <div className="site-section" id="inversiones">
                <div className="container mb-4">
                    <div className="row">
                        <div className="col-10 offset-1 ">
                        
                            <p className="h5 italic mb-5 blue1">
                                Queremos maximizar la rentabilidad del negocio de granos a través del mercado de capitales. Le ofrecemos a nuestros clientes la posibilidad de invertir en una amplia gama de productos.
                            </p>
                            <div className="row pt-4">
                                <div className="col-12 col-md-6 mb-1">
                                    <ul className="h6 italic blue1 list_equipo">
                                        <li><b>Fondos Comunes de Inversión </b></li>
                                        <li><b>Futuros y Opciones</b></li>
                                        <li><b>Acciones</b></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ul className="h6 italic  blue1 list_equipo">
                                        <li><b>Títulos Públicos y Privados</b></li>
                                        <li><b>Cauciones </b></li>
                                        <li><b>CEDEARs</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={aperturaRef}></div>
            <ModAgentes />
            <div className="modal" id="idoneos" role="dialog">

                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Listado de Idoneos</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img className="img-fluid"
                                src={window.location.origin + "/imgs/n_resoluciones.jpg"}
                            />
                            <table className="table table-striped">
                                <tbody>
                                    <tr><td>CARLOS DANIEL MORGAN</td></tr>
                                    <tr><td>LUCIO GARCÍA MANSILLA</td></tr>
                                    <tr><td>MARCELO ACCARI</td></tr>
                                    <tr><td>GUSTAVO SARAVIA</td></tr>
                                    <tr><td>PATRICIO GILLIGAN</td></tr>
                                    <tr><td>AGUSTÍN RICARDO MARCOS</td></tr>
                                    <tr><td>SEBASTIÁN CRISTI</td></tr>
                                    <tr><td>EDMUNDO DONNELLY</td></tr>
                                    <tr><td>LEONARDO MARTINEZ</td></tr>
                                    <tr><td>GONZALO SZTYRLE</td></tr>
                                    <tr><td>ROCIO NISTALL CAMPBELL</td></tr>
                                    <tr><td>ALEJO ORTIZ</td></tr>
                                    <tr><td>FRANCISCO MARRA</td></tr>
                                    <tr><td>HECTOR GAGLIARDI</td></tr>
                                    <tr><td>RODRIGO BELLEZZE</td></tr>
                                    <tr><td>FRANCISCO GANDÍA</td></tr>
                                    <tr><td>GUADALUPE GARDIOL</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-section  bg-light" id="inversiones">
                <div className="container mb-4">
                    <div className="row justify-content-center">
                        <a className="m-2" href="https://www.allariaagro.com.ar/PDF/aranceles_Allaria_Agrofinanzas.pdf" target="_blank" rel="noreferrer" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                          <div className="card-merc-capitales txt-primary header-3">
                            <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                            <p className="p-2 m-0">Tabla de comisiones</p>
                          </div>
                        </a>
                        <button type="button" className="card-merc-capitales txt-primary header-3 m-2" data-toggle="modal" data-target="#idoneos" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                        <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                          <p className="p-2 m-0">Listado de Idóneos</p>
                        </button>
                        <button type="button" className="card-merc-capitales txt-primary header-3 m-2" data-toggle="modal" data-target="#fatca" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                        <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                          <p className="p-2 m-0">FATCA</p>
                        </button>
                        <button type="button" className="card-merc-capitales txt-primary header-3 m-2" data-toggle="modal" data-target="#ocde" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                        <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                          <p className="p-2 m-0">OCDE</p>
                        </button>
                        {/* <a className="m-2" href="https://www.allariaagro.com.ar/PDF/AAF-AperturaPHumana.pdf" target="_blank" rel="noreferrer" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                        <div className="card-merc-capitales txt-primary header-3">
                            <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                            <p className="p-2">Apertura Persona Física</p>
                          </div>
                        </a> */}
                        <a className="m-2" href="https://apertura-cuenta.allariaagro.com.ar/personal" target="_blank" rel="noreferrer" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                        <div className="card-merc-capitales txt-primary header-3">
                            <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                            <p className="p-2">Apertura Persona Física</p>
                          </div>
                        </a>
                        <a className="m-2" href="https://www.allariaagro.com.ar/PDF/AAF-AperturaPJuridica.pdf" target="_blank" rel="noreferrer" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                          <div className="card-merc-capitales txt-primary header-3">
                            <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                            <p className="p-2">Apertura Persona Jurídica</p>
                          </div>
                        </a>
                        <button type="button" className="card-merc-capitales txt-primary header-3 m-2" data-toggle="modal" data-target="#agentes-productores" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" data-aos-once="true">
                          <img className="p-3" src={window.location.origin+"/imgs/icons/icono-tablas.png"} alt="" />
                          <p className="p-2 m-0">Agentes Productores</p>
                        </button>
                    </div>
                </div>
            </div>
            <ModFatca />
            <ModOcde />

            <div className="modal" id="agentes-productores" role="dialog">
                <div className="modal-dialog mw-100" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="h4 bold blue2 mb-4">Agentes Productores</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-9">
                                        {/* <p className="h5 italic mb-5 blue1">La Comisión Nacional de Valores regula la actividad de los Agentes registrados en la República Argentina. Allaria Agrofinanzas como Agente de Liquidación y Compensación y Agente de Negociación propio, interactúa con otros agentes registrados como Productores , proveyéndoles plataformas de operatoria y liquidación, y coordinando en forma conjunta el mejor asesoramiento a los clientes.</p> */
                                        <p className="h5 italic mb-5 blue1">La Comisión Nacional de Valores regula la actividad de los Agentes registrados en la República Argentina. Allaria Agrofinanzas como Agente de Negociación, interactúa con otros agentes registrados como Productores , proveyéndoles plataformas de operatoria y liquidación, y coordinando en forma conjunta el mejor asesoramiento a los clientes.</p>}
                                        <hr/>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <b>NOMBRE/RAZON SOCIAL</b>
                                                    </td>
                                                    <td>
                                                        <b>MATRICULA EN CNV</b>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>WINA S.A.</td>
                                                    <td>452</td>
                                                </tr>
                                                <tr>
                                                    <td>MORGAN DELFIN</td>
                                                    <td>1470</td>
                                                </tr>
                                                <tr>
                                                    <td>MARIA ANA GOBELLI</td>
                                                    <td>1153</td>
                                                </tr>
                                                <tr>
                                                    <td>SEBASTIAN PEREYRA MOINE</td>
                                                    <td>367</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

              <div className="relaciones-publico d-flex justify-content-center mb-5" >
                <div className="content d-flex justify-content-center box-shadow animate__animated animate__slideInRight">
                  <div className="content_text bg_white">
                    <h2 className="h4 bold m-0">Relaciones con el público</h2>
                    <p className="">
                    De acuerdo a lo establecido en las Normas Técnicas (N.T. 2013) de la CNV y modificatorias, <b>Allaria Agrofinanzas S.A.</b> ha designado a una persona Responsable de Relaciones con el Público, cuya función será atender reclamos de clientes e informarlos inmediatamente al Directorio y a CNV
                    </p>
                    <p className="txt-primary pt-3">NUESTRO RESPONSABLE DE RELACIONES CON EL PÚBLICO</p>
                    <p><b>Patricio Tomás Gilligan</b></p>
                    <p>E-mail: <a className="txt-primary" href="mailto:responsable.cliente@allariaagro.com.ar">responsable.cliente@allariaagro.com.ar</a></p>
                  </div>

                  <img src={window.location.origin+"/imgs/rrpp-03.jpg"} className="" alt="" />
                </div>
              </div>
            
        </div>
    )
}

export default MercadoCapitales;
